import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { Webhook, WebhookHistory } from "../../entities/webhook";
import { getWebhook, getWebhookHistory, getWebhooks } from "./thunks";
import { RootState } from "..";

interface WebhookState {
  loading: boolean;
  webhooks: Webhook[];
  history: WebhookHistory[];
  webhook?: Webhook;
  webhookLoading: boolean;
}

const initialState: WebhookState = {
  loading: false,
  webhookLoading: false,
  webhooks: [],
  history: [],
};

// const addLoadingCases = (
//   builder: ActionReducerMapBuilder<WheelState>,
//   action: any
// ) => {
//   builder.addCase(action.pending, (state: WheelState) => {
//     state.saveLoading = true;
//   });

//   builder.addCase(action.fulfilled, (state: WheelState) => {
//     state.saveLoading = false;
//   });
// };

export const webhookSlice = createSlice({
  name: "bonus",
  initialState,
  reducers: {
    clearWebhookHistory: (state: Draft<WebhookState>) => {
      state.history = [];
      state.webhook = undefined;
    },
    setWebhook: (
      state: Draft<WebhookState>,
      action: PayloadAction<Webhook>
    ) => {
      state.webhook = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getWebhooks
    builder.addCase(getWebhooks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWebhooks.fulfilled, (state, action) => {
      state.webhooks = action.payload;
      state.loading = false;
    });
    // getWebhookHistory
    builder.addCase(getWebhookHistory.pending, (state) => {
      state.history = [];
      state.loading = true;
    });
    builder.addCase(getWebhookHistory.fulfilled, (state, action) => {
      state.history = action.payload;
      state.loading = false;
    });
    // getWebhook
    builder.addCase(getWebhook.pending, (state) => {
      state.webhookLoading = true;
    });
    builder.addCase(getWebhook.fulfilled, (state, action) => {
      state.webhook = action.payload;
      state.webhookLoading = false;
    });
  },
});

export const { clearWebhookHistory, setWebhook } = webhookSlice.actions;

export const selectWebhookState = (state: RootState) => state.webhook;

export default webhookSlice.reducer;
