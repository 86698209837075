import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../config/config";
import { agent, encodeGetParams } from "../../api";
import {
  ElementOrder,
  GetJackpotParticipateTranslation,
  IGetProfileJackpotConfigList,
  IJackpotProfile,
  JackpotDatabaseContent,
  JackpotParticipateTranslation,
  JackpotProfileList,
  JackpotStatusType,
  JackpotTabStatus,
  PopupSettingsForm,
  RequestNotificationGlobal,
  ResponseElementOrder,
  ResponseHowToWin,
  ResponseHowToWinlangs,
  ResponseJackpotList,
  ResponseJackpotMost,
  ResponseJackpotParticipate,
  ResponseJackpotPrize,
  ResponseNotification,
  ResponseNotificationLangs,
  ResponsePopupLangs,
  ResponsePopupSettings,
  ResponsePostContentLang,
  ResponsePostGlobal,
} from "../../entities/jackpotGame";
import { addNotification } from "../Notification/notificateStore";

const DEFAULT_URL = `${apiUrlV1}/organization/game/jackpot`;
const DEFAULT_STATUS = {
  GlobalSettings: false,
  ContentLocalization: false,
  PopupSettings: false,
  HowToWin: false,
  EndNotificationSettings: false,
  Parcitipate: false,
  MostChance: false,
  PageElementOrder: false,
};

//Получение списка джекпотов
export const getPrizeJackpot = createAsyncThunk(
  "jackpot/prize-list",
  async () => {
    const query = encodeGetParams({ page: 1, limit: 250 });
    const req = await agent.get(`${DEFAULT_URL}/list${query}`);
    if (req.ok) {
      const res = (await req.json()) as ResponseJackpotList;
      return res.jackpotPrizeList;
    }
    return [];
  }
);
export const getStatusControle = createAsyncThunk(
  "jackpot/get-status-checker",
  async (jackpotId: number) => {
    const req = await agent.get(`${DEFAULT_URL}/status-control/${jackpotId}`);
    if (req.ok) {
      const res = (await req.json()) as { config: JackpotTabStatus };
      return res.config;
    }
    return DEFAULT_STATUS;
  }
);

//Проверерка статуса джекпота
export const jackpotStatusChecker = createAsyncThunk(
  "jackpot/status-checker",
  async (
    props: { jackpotId: number; target: JackpotStatusType },
    thunksApi
  ) => {
    const req = await agent.post(`${DEFAULT_URL}/status-control/update`, {
      body: JSON.stringify(props),
    });
    if (req.ok) {
      const res = (await req.json()) as { config: JackpotTabStatus };
      return res.config;
    }
    return DEFAULT_STATUS;
  }
);
//Получение глобальных настроект по id джекпота
export const getPrizeJackpotById = createAsyncThunk(
  "jackpot/get-jackpot-by-id",
  async (id: string, thunksApi) => {
    const req = await agent.get(`${DEFAULT_URL}/${id}`);
    if (req.ok) {
      // thunksApi.dispatch(getStatusControle(Number(id)));
      const res = (await req.json()) as ResponseJackpotPrize;
      return res;
    }
    return undefined;
  }
);

//create глобальных настроект по id джекпота
export const postPrizeJackpotById = createAsyncThunk(
  "jackpot/post-jackpot-by-id",
  async (data: ResponsePostGlobal, thunksApi) => {
    const req = await agent.post(`${DEFAULT_URL}`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      const res = (await req.json()) as { id: number };
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: res.id, target: "GlobalSettings" })
      );

      thunksApi.dispatch(getPrizeJackpotById(res.id.toString()));

      return res.id as number;
    }
    return undefined;
  }
);

//Получение глобальных настроект по id джекпота
export const patchPrizeJackpotById = createAsyncThunk(
  "jackpot/patch-jackpot-by-id",
  async (props: { data: ResponsePostGlobal; id: number }, thunksApi) => {
    const { data, id } = props;
    const req = await agent.patch(`${DEFAULT_URL}/${id}`, {
      body: JSON.stringify(data),
    });
    if (req.status === 200) {
      thunksApi.dispatch(getPrizeJackpotById(props.id.toString()));
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: id, target: "GlobalSettings" })
      );
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
    }
  }
);

//Удаление джекпота по id
export const deletePrizeJackpotById = createAsyncThunk(
  "jackpot/delete-jackpot-by-id",
  async (id: string, thunksApi) => {
    const req = await agent.delete(`${DEFAULT_URL}/${id}`);
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successful removal" })
      );
      thunksApi.dispatch(getPrizeJackpot());
      return null;
    }
    if (!req.ok) {
      const result = (await req.json()) as { message: string };
      thunksApi.dispatch(
        addNotification({
          status: "error",
          text: "It is not possible to delete the jackpot used in the profile.",
        })
      );
      throw result.message;
    }
    return null;
  }
);
//
export const getContentJacpotById = createAsyncThunk(
  "jackpot/get-content-by-id",
  async (id: string) => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/content-localization`);
    if (req.ok) {
      const res = (await req.json()) as {
        langs: ResponsePostContentLang[];
        globalSettings: JackpotDatabaseContent;
      };
      return res;
    }
    return undefined;
  }
);
//
export const postContentById = createAsyncThunk(
  "jackpot/post-content-by-id",
  async (data: any, thunksApi) => {
    const req = await agent.post(
      `${DEFAULT_URL}/content-localization/create-global-settings`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: data.jackpotId,
          target: "ContentLocalization",
        })
      );
      thunksApi.dispatch(getContentJacpotById(`${data.jackpotId}`));
    }
  }
);
export const patchContentById = createAsyncThunk(
  "jackpot/patch-content-by-id",
  async (data: any, thunksApi) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/content-localization/update-global-settings`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: data.jackpotId,
          target: "ContentLocalization",
        })
      );
      thunksApi.dispatch(getContentJacpotById(`${data.jackpotId}`));
    }
    if (!req.ok && req.status === 404) {
      thunksApi.dispatch(postContentById(data));
    }
  }
);

export const postContentTranslationById = createAsyncThunk(
  "jackpot/post-content-translation-by-id",
  async (data: any, thunksApi) => {
    const req = await agent.post(
      `${DEFAULT_URL}/content-localization/add-lang`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: data.jackpotId,
          target: "ContentLocalization",
        })
      );
      thunksApi.dispatch(getContentJacpotById(`${data.jackpotId}`));
    }
  }
);

export const patchContentTranslationById = createAsyncThunk(
  "jackpot/patch-content-translation-by-id",
  async (data: any, thunksApi) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/content-localization/update-lang`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: data.jackpotId,
          target: "ContentLocalization",
        })
      );
      thunksApi.dispatch(getContentJacpotById(`${data.jackpotId}`));
    }
  }
);

export const getPopup = createAsyncThunk(
  `jackpot/get-popup-settings`,
  async (id: number): Promise<ResponsePopupSettings | undefined> => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/popup-settings`);
    if (req.ok) {
      const res = (await req.json()) as ResponsePopupSettings;
      return res;
    }
    return undefined;
  }
);

export const postPopupSettings = createAsyncThunk(
  `jackpot/post-popup-settings`,
  async (
    data: { jackpotId: number; globalSettings: PopupSettingsForm },
    thunksApi
  ) => {
    const req = await agent.post(`${DEFAULT_URL}/popup-settings`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: data.jackpotId,
          target: "PopupSettings",
        })
      );
      thunksApi.dispatch(getPopup(data.jackpotId));
    }
  }
);

export const patchPopupSettings = createAsyncThunk(
  `jackpot/patch-popup-settings`,
  async (
    data: { jackpotId: number; globalSettings: PopupSettingsForm },
    thunksApi
  ) => {
    const req = await agent.patch(`${DEFAULT_URL}/popup-settings`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: data.jackpotId,
          target: "PopupSettings",
        })
      );
      thunksApi.dispatch(getPopup(data.jackpotId));
    }
  }
);

export const postPopupTranslationById = createAsyncThunk(
  "jackpot/post-popup-translation-by-id",
  async (props: { jackpotId: number; lang: ResponsePopupLangs }, thunksApi) => {
    const req = await agent.post(`${DEFAULT_URL}/popup-settings/add-lang`, {
      body: JSON.stringify(props),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: props.jackpotId,
          target: "PopupSettings",
        })
      );
      thunksApi.dispatch(getPopup(props.jackpotId));
    }
  }
);

export const patchPopupTranslationById = createAsyncThunk(
  "jackpot/patch-popup-translation-by-id",
  async (props: { jackpotId: number; lang: ResponsePopupLangs }, thunksApi) => {
    const req = await agent.patch(`${DEFAULT_URL}/popup-settings/update-lang`, {
      body: JSON.stringify(props),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: props.jackpotId,
          target: "PopupSettings",
        })
      );
      thunksApi.dispatch(getPopup(props.jackpotId));
    }
  }
);

//получение parcitipate
export const getParcitipateById = createAsyncThunk(
  "jackpot/get-parcitipate-by-id",
  async (id: number) => {
    try {
      const req = await agent.get(`${DEFAULT_URL}/${id}/parcitipate`);
      if (req.ok) {
        const res = (await req.json()) as {
          id: number;
          config: ResponseJackpotParticipate;
          langs: GetJackpotParticipateTranslation[];
        };
        const result: {
          id: number;
          config: ResponseJackpotParticipate;
          langs: JackpotParticipateTranslation[];
        } = {
          id: res.id,
          config: res.config,
          langs: res.langs.map((item) => ({
            ...item,
            textContent: {
              ...item.textContent,
              allButtonTitleConfiguration: item.textContent
                .buttonTitleConfiguration
                ? item.textContent.buttonTitleConfiguration
                : item.textContent.allButtonTitleConfiguration!,
            },
          })),
        };
        return result;
      }
      if (!req.ok) {
        const result = (await req.json()) as { message: string };
        throw result.message;
      }
      return undefined;
    } catch (error) {
      throw error;
    }
  }
);
//создание parcitipate
export const postParcitipateById = createAsyncThunk(
  "jackpot/post-parcitipate-by-id",
  async (
    props: { id: number; data: ResponseJackpotParticipate },
    thunksApi
  ) => {
    const req = await agent.post(`${DEFAULT_URL}/${props.id}/parcitipate`, {
      body: JSON.stringify(props.data),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: props.id, target: "Parcitipate" })
      );

      thunksApi.dispatch(getParcitipateById(props.id));
    }
  }
);
//Измение parcitipate
export const patchParcitipateById = createAsyncThunk(
  "jackpot/patch-parcitipate-by-id",
  async (props: { id: number; data: any }, thunksApi) => {
    const req = await agent.patch(`${DEFAULT_URL}/${props.id}/parcitipate`, {
      body: JSON.stringify(props.data),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: props.id, target: "Parcitipate" })
      );
      thunksApi.dispatch(getParcitipateById(props.id));
    }
  }
);

export const postParcitipateTranslate = createAsyncThunk(
  "jackpot/post-parcitipate-translate",
  async (props: { id: number; data: any }, thunksApi) => {
    const req = await agent.post(
      `${DEFAULT_URL}/${props.id}/parcitipate/add-lang`,
      {
        body: JSON.stringify(props.data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: props.id, target: "Parcitipate" })
      );
      thunksApi.dispatch(getParcitipateById(props.id));
    }
  }
);

export const patchParcitipateTranslate = createAsyncThunk(
  "jackpot/patch-parcitipate-translate",
  async (props: { id: number; data: any }, thunksApi) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/${props.id}/parcitipate/update-lang`,
      {
        body: JSON.stringify(props.data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: props.id, target: "Parcitipate" })
      );
      thunksApi.dispatch(getParcitipateById(props.id));
    }
  }
);

//получение most
export const getMostById = createAsyncThunk(
  "jackpot/get-most-by-id",
  async (id: number) => {
    try {
      const req = await agent.get(`${DEFAULT_URL}/${id}/most-chance`);
      if (req.ok) {
        const res = (await req.json()) as {
          config: ResponseJackpotMost;
          langs: GetJackpotParticipateTranslation[];
        };

        const result: {
          config: ResponseJackpotMost;
          langs: JackpotParticipateTranslation[];
        } = {
          config: res.config,
          langs: res.langs.map((item) => ({
            ...item,
            textContent: {
              ...item.textContent,
              allButtonTitleConfiguration: item.textContent
                .buttonTitleConfiguration
                ? item.textContent.buttonTitleConfiguration
                : item.textContent.allButtonTitleConfiguration!,
            },
          })),
        };
        return result;
      }
      if (!req.ok) {
        const result = (await req.json()) as { message: string };
        throw result.message;
      }
      return undefined;
    } catch (error) {
      throw error;
    }
  }
);

//создание most
export const postMostById = createAsyncThunk(
  "jackpot/post-most-by-id",
  async (props: { id: number; data: ResponseJackpotMost }, thunksApi) => {
    const req = await agent.post(`${DEFAULT_URL}/${props.id}/most-chance`, {
      body: JSON.stringify(props.data),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: props.id, target: "MostChance" })
      );
      thunksApi.dispatch(getMostById(props.id));
    }
  }
);

//Измение most
export const patchMostById = createAsyncThunk(
  "jackpot/patch-most-by-id",
  async (props: { id: number; data: ResponseJackpotMost }, thunksApi) => {
    const req = await agent.patch(`${DEFAULT_URL}/${props.id}/most-chance`, {
      body: JSON.stringify(props.data),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: props.id, target: "MostChance" })
      );
      thunksApi.dispatch(getMostById(props.id));
    }
  }
);
export const postMostChanceTranslate = createAsyncThunk(
  "jackpot/post-most-translate",
  async (props: { id: number; data: any }, thunksApi) => {
    const req = await agent.post(
      `${DEFAULT_URL}/${props.id}/mostChance/add-lang`,
      {
        body: JSON.stringify(props.data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: props.id, target: "MostChance" })
      );
      thunksApi.dispatch(getMostById(props.id));
    }
  }
);

export const patchMostChanceTranslate = createAsyncThunk(
  "jackpot/patch-most-translate",
  async (props: { id: number; data: any }, thunksApi) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/${props.id}/mostChance/update-lang`,
      {
        body: JSON.stringify(props.data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: props.id, target: "MostChance" })
      );
      thunksApi.dispatch(getMostById(props.id));
    }
  }
);
// how to win

export const gatHowToWin = createAsyncThunk(
  "jackpot/get-how-to-win",
  async (id: number): Promise<undefined | ResponseHowToWin> => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/how-to-win`);
    if (req.ok) {
      const res = await req.json();
      return res;
    }
    return undefined;
  }
);
export const postHowToWinGlobal = createAsyncThunk(
  "jackpot/post-how-to-win-global",
  async (data: { globalSettings: any; jackpotId: number }, thunksApi) => {
    const req = await agent.post(
      `${DEFAULT_URL}/how-to-win/create-global-settings`,
      {
        body: JSON.stringify(data),
      }
    );

    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: data.jackpotId, target: "HowToWin" })
      );
      thunksApi.dispatch(gatHowToWin(data.jackpotId));
    }
  }
);

export const patchHowToWinGlobal = createAsyncThunk(
  "jackpot/patch-how-to-win-global",
  async (data: { globalSettings: any; jackpotId: number }, thunksApi) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/how-to-win/update-global-settings`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: data.jackpotId, target: "HowToWin" })
      );
      thunksApi.dispatch(gatHowToWin(data.jackpotId));
    }
  }
);
export const postHowToWinTranslation = createAsyncThunk(
  "jackpot/post-how-to-win-translation",
  async (
    data: { jackpotId: number; lang: ResponseHowToWinlangs },
    thunksApi
  ) => {
    const req = await agent.post(`${DEFAULT_URL}/how-to-win/add-lang`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: data.jackpotId, target: "HowToWin" })
      );
      thunksApi.dispatch(gatHowToWin(data.jackpotId));
    }
  }
);
export const patchHowToWinTranslation = createAsyncThunk(
  "jackpot/patch-how-to-win-translation",
  async (
    data: { jackpotId: number; lang: ResponseHowToWinlangs },
    thunksApi
  ) => {
    const req = await agent.patch(`${DEFAULT_URL}/how-to-win/update-lang`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({ jackpotId: data.jackpotId, target: "HowToWin" })
      );
      thunksApi.dispatch(gatHowToWin(data.jackpotId));
    }
  }
);

// how to win end

export const getProfiles = createAsyncThunk(
  "jackpot/get-profile-list",
  async () => {
    const req = await agent.get(`${DEFAULT_URL}/profile/list`);
    if (req.ok) {
      const res = (await req.json()) as { list: JackpotProfileList[] };
      return res.list;
    }
    return [];
  }
);

export const getProfileById = createAsyncThunk(
  "jackpot/get-profile",
  async (id: string, thunksApi) => {
    const req = await agent.get(`${DEFAULT_URL}/profile/${id}`);
    if (req.ok) {
      const res = (await req.json()) as IJackpotProfile;
      return res;
    }
  }
);

export const getProfileConfigList = createAsyncThunk(
  "jackpot/get-profile-config",
  async (id: number) => {
    const query = encodeGetParams({ page: 1, limit: 250, profileId: id });
    const req = await agent.get(`${DEFAULT_URL}/profile/config/list${query}`);
    if (req.ok) {
      const res = (await req.json()) as IGetProfileJackpotConfigList;
      return res;
    }
    return undefined;
  }
);

export const postProfile = createAsyncThunk(
  "jackpot/post-profile",
  async (data: IJackpotProfile, thunksApi) => {
    try {
      const req = await agent.post(`${DEFAULT_URL}/profile`, {
        body: JSON.stringify(data),
      });
      if (req.ok) {
        const result = (await req.json()) as { profileId: number };
        thunksApi.dispatch(getProfileById(`${result.profileId}`));
        thunksApi.dispatch(getProfiles());
        return result.profileId;
      }
      if (!req.ok) {
        const result = (await req.json()) as { message: string };
        thunksApi.dispatch(
          addNotification({ status: "error", text: result.message })
        );
        throw result.message;
      }
    } catch (error) {
      throw error;
    }
  }
);
export const patchProfile = createAsyncThunk(
  "jackpot/patch-profile",
  async (data: IJackpotProfile, thunksApi) => {
    try {
      // TODO убрать когда приведём профиль к единому виду
      const body = {
        ...data,
        config: {
          sortMode: data.sortMode,
        },
      };
      const req = await agent.patch(`${DEFAULT_URL}/profile/${data.id}`, {
        body: JSON.stringify(body),
      });
      if (req.ok) {
        thunksApi.dispatch(getProfileById(`${data.id}`));
        thunksApi.dispatch(getProfiles());
      }
      if (!req.ok) {
        const result = (await req.json()) as { message: string };
        thunksApi.dispatch(
          addNotification({ status: "error", text: result.message })
        );
        throw result.message;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const postProfileConfigList = createAsyncThunk(
  "jackpot/post-profile-config",
  async (
    props: {
      data: {
        configList: {
          profileId: number;
          gameEntityId: number;
        }[];
      };
      profileId: number;
    },
    thunksApi
  ) => {
    try {
      const req = await agent.post(
        `${DEFAULT_URL}/profile/config/${props.profileId}`,
        {
          body: JSON.stringify(props.data),
        }
      );
      if (req.ok) {
        const res = (await req.json()) as { id: number };
        thunksApi.dispatch(getProfileConfigList(res.id));
        thunksApi.dispatch(getProfileById(`${res.id}`));
        thunksApi.dispatch(
          addNotification({ status: "success", text: "Successfully saved" })
        );
      }
      if (!req.ok) {
        const res = (await req.json()) as { message: string };
        thunksApi.dispatch(
          addNotification({ status: "error", text: res.message })
        );
        throw res.message;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getElementOrder = createAsyncThunk(
  "jackpot/get-case-page-element-order",
  async (id: number) => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/element-order`);
    if (req.ok) {
      const res = (await req.json()) as ResponseElementOrder;
      return res.config.items;
    }
  }
);

export const postElementOrder = createAsyncThunk(
  "jackpot/post-case-page-element-order",
  async (props: { data: any; id: number }, thunksApi) => {
    const req = await agent.post(`${DEFAULT_URL}/${props.id}/element-order`, {
      body: JSON.stringify({ config: props.data }),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: props.id,
          target: "PageElementOrder",
        })
      );
      thunksApi.dispatch(getElementOrder(props.id));
    }
  }
);

export const patchElementOrder = createAsyncThunk(
  "jackpot/patch-case-page-element-order",
  async (props: { data: ElementOrder[]; id: number }, thunksApi) => {
    const body = {
      config: {
        items: props.data.map((item, index) => ({
          title: item.title,
          position: index + 1,
        })),
      },
    };
    const req = await agent.patch(`${DEFAULT_URL}/${props.id}/element-order`, {
      body: JSON.stringify(body),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: props.id,
          target: "PageElementOrder",
        })
      );
      thunksApi.dispatch(getElementOrder(props.id));
    }
  }
);

//[GET] /organization/game/jackpot/{jackpotId}/end-notification
export const getNotification = createAsyncThunk(
  "jackpot/get-notification",
  async (jackpotId: number) => {
    const res = await agent.get(`${DEFAULT_URL}/${jackpotId}/end-notification`);
    if (res.ok) {
      const req = (await res.json()) as ResponseNotification;
      return req;
    }
  }
);
//[POST] /organization/game/jackpot/end-notification/create-global-settings
export const postGlobalNotification = createAsyncThunk(
  "jackpot/post-global-notification",
  async (
    data: {
      jackpotId: number;
      globalSettings: RequestNotificationGlobal;
    },
    thunksApi
  ) => {
    const req = await agent.post(
      `${DEFAULT_URL}/end-notification/create-global-settings`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: data.jackpotId,
          target: "EndNotificationSettings",
        })
      );
      thunksApi.dispatch(getNotification(data.jackpotId));
    }
  }
);
//[PATCH] /organization/game/jackpot/end-notification/update-global-settings
export const patchGlobalNotification = createAsyncThunk(
  "jackpot/patch-global-notification",
  async (
    data: {
      jackpotId: number;
      globalSettings: RequestNotificationGlobal;
    },
    thunksApi
  ) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/end-notification/update-global-settings`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: data.jackpotId,
          target: "EndNotificationSettings",
        })
      );
      thunksApi.dispatch(getNotification(data.jackpotId));
    }
  }
);
//[POST] /organization/game/jackpot/end-notification/add-lang
export const postNotificationTranslation = createAsyncThunk(
  "jackpot/post-notification-translation",
  async (
    data: { jackpotId: number; lang: ResponseNotificationLangs },
    thunksApi
  ) => {
    const req = await agent.post(`${DEFAULT_URL}/end-notification/add-lang`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: data.jackpotId,
          target: "EndNotificationSettings",
        })
      );
      thunksApi.dispatch(getNotification(data.jackpotId));
    }
  }
);
//[PATCH] /organization/game/jackpot/end-notification/update-lang
export const patchNotificationTranslation = createAsyncThunk(
  "jackpot/patch-notification-translation",
  async (
    data: { jackpotId: number; lang: ResponseNotificationLangs },
    thunksApi
  ) => {
    const req = await agent.patch(
      `${DEFAULT_URL}/end-notification/update-lang`,
      {
        body: JSON.stringify(data),
      }
    );
    if (req.ok) {
      thunksApi.dispatch(
        addNotification({ status: "success", text: "Successfully saved" })
      );
      thunksApi.dispatch(
        jackpotStatusChecker({
          jackpotId: data.jackpotId,
          target: "EndNotificationSettings",
        })
      );
      thunksApi.dispatch(getNotification(data.jackpotId));
    }
  }
);
