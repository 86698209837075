import { redirect, RouteObject } from "react-router-dom";
import { Layout } from "../Layout/Layout";
import React from "react";

const PiggyBankPage = React.lazy(
  async () => await import("../Pages/PiggyBank/PiggyBankPage")
);
const PiggyDatabasePage = React.lazy(
  async () => await import("../Pages/PiggyBank/Database/DatabasePage")
);

const PiggyBankProfilePage = React.lazy(
  async () => await import("../Pages/PiggyBank/Profile/ProfilePage")
);

export const RouterPiggyBank: RouteObject[] = [
  {
    path: "/game/piggyBank/",
    loader: async () => {
      // Здесь вы можете получить значение :table из базы данных
      const table = "database";
      return redirect(`/game/piggyBank/${table}`);
    },
  },
  {
    path: "/game/piggyBank/:table/",
    element: (
      <Layout>
        <PiggyBankPage />
      </Layout>
    ),
  },
  {
    path: "/game/piggyBank/profile/create",
    element: (
      <Layout>
        <PiggyBankProfilePage />
      </Layout>
    ),
  },
  {
      path: "/game/piggyBank/profiles/create",
      loader: async () => {
        return redirect(`/game/piggyBank/profile/create`);
      },
    },
  {
    path: "/game/piggyBank/profile/",
    loader: async () => {
      return redirect(`/game/piggyBank/profiles/`);
    },
  },
  {
    path: "/game/piggyBank/profile/:id/",
    loader: async ({ params }) => {
      const tab = "global";
      return redirect(`/game/piggyBank/profile/${params.id}/${tab}`);
    },
  },
  {
    path: "/game/piggyBank/profiles/:id/",
    loader: async ({ params }) => {
      const tab = "global";
      return redirect(`/game/piggyBank/profile/${params.id}/${tab}`);
    },
  },
  {
    path: "/game/piggyBank/profile/:id/:tab",
    element: (
      <Layout>
        <PiggyBankProfilePage />
      </Layout>
    ),
  },
  {
    path: "/game/piggyBank/database/create",
    element: (
      <Layout>
        <PiggyDatabasePage />
      </Layout>
    ),
  },
  {
    path: "/game/piggyBank/database/:id/",
    loader: async ({ params }) => {
      const tab = "global";
      return redirect(`/game/piggyBank/database/${params.id}/${tab}`);
    },
  },
  {
    path: "/game/piggyBank/database/:id/:tab/",
    element: (
      <Layout>
        <PiggyDatabasePage />
      </Layout>
    ),
  },
];
