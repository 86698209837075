/* eslint-disable no-useless-escape */
import { format } from "date-fns";
import { CasePricingForm, RequstCasePricintForm } from "../../entities/cases";

export const parsePricingData = (
  data: CasePricingForm
): RequstCasePricintForm => {
  return {
    progressBarSettings: {
      enabled: data.progressBarSettings.progressBarSettingsActive,
      progressBarType: data.progressBarSettings.progressBarType,
      progressValue: data.progressBarSettings.porgressValue,
    },
    caseType: {
      caseType: data.caseSettings.caseType,
      primaryCurrency: data.caseSettings.primaryCurrency,
      caseAmount: data.caseSettings.caseAmount,
      countryAndRegionSettings: data.caseSettings.countryAndRegionSettings,
      limitPlatformCases: {
        enabled: data.caseSettings.limitPlatformCases > 0,
        amount: data.caseSettings.limitPlatformCases,
      },
      limitUserCases: {
        enabled: data.caseSettings.limitUserCases > 0,
        amount: data.caseSettings.limitUserCases,
      },
      discountCaseAmount: {
        enabled: data.caseSettings.discountCaseAmount > 0,
        amount: data.caseSettings.discountCaseAmount,
      },
      timeUntilNextCases: {
        timeUntilNextCase: !!data.caseSettings.TimeUntilNextCase
          ? format(new Date(data.caseSettings.TimeUntilNextCase), "HH:mm:ss")
          : "",
        enabled: !!data.caseSettings.TimeUntilNextCase,
      },
    },
  };
};

export const parseResponsePricingCase = (
  data: any
): {
  primaryCurrency: string;
  caseAmount: number;
  discountCaseAmount: number;
  TimeUntilNextCase: string | null;
  limitPlatformCases: number;
  limitUserCases: number;
} => {
  return {
    primaryCurrency: data.primaryCurrency,
    caseAmount: data.caseAmount,
    discountCaseAmount: data.discountCaseAmount.amount,
    TimeUntilNextCase: data.timeUntilNextCases.enabled
      ? `2024-12-01T${data.timeUntilNextCases.timeUntilNextCase}`
      : null,
    limitPlatformCases: data.limitPlatformCases.amount,
    limitUserCases: data.limitUserCases.amount,
  };
};
