import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent } from "../../api";
import { apiUrlV1 } from "../../config/config";
import { PiggyEntity, PiggyProfile, ResponseEntityList, ResponseProfileList } from "../../entities/piggyGame";

const piggyGameUrl = apiUrlV1 + "/organization/game/piggy-bank";

/**
 * Получение списка игр Piggy Bank
 */
export const getPiggyEntityList = createAsyncThunk(
  "piggy/get-entity-list",
  async (): Promise<PiggyEntity[]> => {
    try {
      const response = await agent.get(`${piggyGameUrl}/read/list`);
      if (response.ok) {
        const data = (await response.json()) as ResponseEntityList;
        return data.data;
      }
      if (!response.ok) {
        throw Error("No response");
      }
    } catch (error) {
      throw Error("Request is not found");
    }

    return [];
  }
);
/**
 * Удаление игры по ID
 * @param {number} id - ID игры для удаления.
 * После удаления игры срабатывает чанк на загрузку списка игр. {@link getPiggyEntityList()}
 */
export const deletePiggyEntity = createAsyncThunk(
  'piggy/delete-entity',
  async (param: { id: number }, thunksApi) => {
    try {
      const response = await agent.delete(`${piggyGameUrl}/delete/${param.id}`)
      if (response.ok) {
        thunksApi.dispatch(getPiggyEntityList());
      }
      if (!response.ok) {
        throw Error("No response");
      }
    } catch (error) {
      throw Error("Request is not found");
    }
  }
)

/**
 * Получение списка профилей игры Piggy Bank
 */
export const getPiggyProfilesList = createAsyncThunk(
  "piggy/get-profile-list",
  async (): Promise<PiggyProfile[]> => {
    try {
      const response = await agent.get(`${piggyGameUrl}/profile/read/list`);
      if (response.ok) {
        const data = (await response.json()) as ResponseProfileList;
        return data.data;
      }
      if (!response.ok) {
        throw Error("No response");
      }
    } catch (error) {
      throw Error("Request is not found");
    }

    return [];
  }
);

/**
 * Удаление профиля по ID
 * @param {number} id - ID профиля для удаления.
 * После удаления ппрофиля срабатывает чанк на загрузку списка игр. {@link getPiggyEntityList()}
 */
export const deletePiggyProfile = createAsyncThunk(
  'piggy/delete-profile',
  async (param: { id: number }, thunksApi) => {
    try {
      const response = await agent.delete(`${piggyGameUrl}/profile/delete/${param.id}`)
      if (response.ok) {
        thunksApi.dispatch(getPiggyProfilesList());
      }
      if (!response.ok) {
        throw Error("No response");
      }
    } catch (error) {
      throw Error("Request is not found");
    }
  }
)