import styled from "@emotion/styled";
import { Avatar, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { NavBarInProfile } from "../navigation/NavBar";
import { BreadCrumbs } from "../Components/Breadcrumbs/Breadcrumbs";
import GroupsIcon from "@mui/icons-material/Groups";
import { useAppDispatch, useAppSelector } from "../store";
import { selectEventState } from "../store/Event/eventStore";
import React from "react";
import { getActiveUsersInMonthly } from "../store/Event/thunks";
import { LoadingButton } from "@mui/lab";
import { getMonthDates } from "../utils/getCurrenMonth";
import { format } from "date-fns";

const AdminHeader = styled.header`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  width: 100%;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 8px 16px 0px #aab4bf26;
  display: flex;
  align-items: center;
`;
const Container = styled.div`
  width: 1680px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1700px) {
    width: 98%;
  }
`;
const Main = styled.main`
  width: 1680px;
  margin: 0px auto;
  @media screen and (max-width: 1700px) {
    width: 98%;
  }
`;
const Header = () => {
  const navigate = useNavigate();
  const { loadingActiveUsers, monthlyActiveUsersCount } =
    useAppSelector(selectEventState);
  const [startDate, currentDate] = getMonthDates();
  return (
    <AdminHeader>
      <Container>
        <Link to="/">
          <Avatar
            variant="rounded"
            sx={{
              width: 60,
              height: 60,
              boxShadow: "0px 0px 16px 0px #0000001A",
              background: "#fff",
              borderRadius: "12px",
              "& svg path": {
                fill: "gray",
              },
            }}
          />
        </Link>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <LoadingButton
            loading={loadingActiveUsers}
            onClick={() => {
              navigate(
                `/events?event=game_opened&fromDate=${format(
                  startDate,
                  "yyyy-MM-dd'T'HH:mm:ss"
                )}&toDate=${format(currentDate, "yyyy-MM-dd'T'HH:mm:ss")}`
              );
            }}
            endIcon={<GroupsIcon />}
          >
            {monthlyActiveUsersCount}
          </LoadingButton>
          <NavBarInProfile />
        </Box>
      </Container>
    </AdminHeader>
  );
};

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const { activeUsersLoaded } = useAppSelector(selectEventState);

  React.useEffect(() => {
    if (!activeUsersLoaded) {
      dispatch(getActiveUsersInMonthly());
    }
  });
  return (
    <>
      <Header />
      <BreadCrumbs />
      <Main>{children}</Main>
    </>
  );
};
