import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../";
import { getProfileById, getProfileConfigList, patchProfile, postProfile, postProfileConfigList } from "../thunks";
import { IJackpotProfile, JackpotPullType } from "../../../entities/jackpotGame";

interface JackpotProfileState {
  loading: boolean;
  saveLoading: boolean;
  global?: IJackpotProfile
  configuration: JackpotPullType[],
  sortMode: boolean;
}

const addLoadingCases = (
  builder: ActionReducerMapBuilder<JackpotProfileState>,
  action: any
) => {
  builder.addCase(action.pending, (state: JackpotProfileState) => {
    state.saveLoading = true;
  });

  builder.addCase(action.fulfilled, (state: JackpotProfileState) => {
    state.saveLoading = false;
  });

  builder.addCase(action.rejected, (state: JackpotProfileState) => {
    state.saveLoading = false;
  });
};

const initialState: JackpotProfileState = {
  loading: false,
  saveLoading: false,
  configuration: [],
  sortMode: true,
}

export const jackpotProfileSlice = createSlice({
  name: "jackpotProfile",
  initialState,
  reducers: {
    clearJackpotProfile: (state) => {
      state.global = undefined;
      state.configuration = [];
    },
    sortModeHandler: (state, action) => {
      state.sortMode = action.payload;
    }
  },
  extraReducers: (builder) => {
    // getProfileConfigList
    builder.addCase(getProfileConfigList.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getProfileConfigList.fulfilled, (state, action) => {
      if (action.payload) {
        const { list, sortMode, count } = action.payload
        state.sortMode = sortMode;

        if (count === 0) {
          state.configuration = []
        } else {
          state.configuration = list.map((item) => ({
            index: item.config.index,
            prizeId: item.gameEntity.id,
            langs: item.gameEntity.langs,
          })).sort((a, b) => a.index - b.index);
        }
      } else {
        state.configuration = [{ index: 1, prizeId: 0, langs: [] }]
      }

      state.loading = false;
    })

    // getProfileById
    builder.addCase(getProfileById.pending, state => {
      state.loading = true;
    })
    builder.addCase(getProfileById.fulfilled, (state, action) => {
      if (action.payload) {
        state.global = { ...action.payload, status: action.payload.status === 'draft' ? 'paused' : action.payload.status };
        // state.sortMode = action.payload.sortMode ? action.payload.sortMode : true;
      } else {
        state.global = undefined;
      }
      state.loading = false;
    })

    const saveActions = [
      postProfile,
      patchProfile,
      postProfileConfigList,
    ]
    saveActions.forEach((action) => addLoadingCases(builder, action));

  },
});

export const { clearJackpotProfile, sortModeHandler } = jackpotProfileSlice.actions;

export const selectProfileJackpot = (state: RootState) => state.jackpot.profile;

export default jackpotProfileSlice.reducer;
