import { format } from "date-fns";
import {
  IJackpotParticipateForm,
  InitialGlobalJackpotDatabase,
  JackpotDatabaseGlobal,
  ResponseJackpotMost,
  ResponseJackpotParticipate,
  ResponseJackpotPrize,
  ResponsePostGlobal,
} from "../../entities/jackpotGame";

export const parseGetGlobalSetting = (
  data: ResponseJackpotPrize
): InitialGlobalJackpotDatabase | undefined => {
  const { jackpotDomainEntity: state } = data;
  const {
    jackpotGlobalSettings,
    additionalInfo,
    paidJackpotSettings,
    prizeSettings,
    timerSettings,
  } = state.config;
  return {
    id: state.id,
    systemName: state.name,
    langs: state.langs,
    color: jackpotGlobalSettings.colorPicker,
    primaryTags: jackpotGlobalSettings.primaryTags,
    cahorts: jackpotGlobalSettings.cahortList,
    autoJoin: jackpotGlobalSettings.autoJoin === "Enabled" ? "true" : "false",
    fakeStats:
      jackpotGlobalSettings.fakeParticipantsStats === "No" ? "false" : "true",
    participants: Number(jackpotGlobalSettings.jackpotParticipants),
    flexibleTimer: timerSettings.flexibleTimer === "No" ? "false" : "true",
    startDate: `${timerSettings.drawDate}T00:00:00`,
    startTime: `2024-12-01T${timerSettings.drawTime}`,
    notes: additionalInfo.jackpotConfigurationNotes,
    type: prizeSettings.type === "Money Default" ? "default" : "flexible",
    primaryCurrency: prizeSettings.settings.primaryCurrency,
    jackpotAmount: prizeSettings.settings.jackpotAmount,
    prizeCountrySettings: prizeSettings.settings.countryAndRegionSettings,
    jackpotOption:
      paidJackpotSettings.paidJackpotOption === "Free" ? "free" : "paid",
    paidPrimaryCurrency: paidJackpotSettings.primatyCurrency,
    feeAmount: paidJackpotSettings.feeAmount,
    paidCountrySettings: paidJackpotSettings.countryAndRegionSettings,
    startDataPeriod: `${timerSettings.startDate}T00:00:00`,
    startTimePeriod: `2024-12-01T${timerSettings.startTime}`,
    showTimer: timerSettings.showTimer,
    published: state.published,
  };
};

export const parseGlobalFormToPost = (
  data: JackpotDatabaseGlobal
): ResponsePostGlobal => {
  return {
    name: data.systemName,
    langs: data.langs,
    config: {
      jackpotGlobalSettings: {
        autoJoin: data.autoJoin === "true" ? "Enabled" : "Disabled",
        cahortList: data.cahorts,
        colorPicker: data.color,
        fakeParticipantsStats: data.fakeStats === "true" ? "Yes" : "No",
        jackpotParticipants: `${data.participants}`,
        primaryTags: data.primaryTags,
      },
      additionalInfo: {
        jackpotConfigurationNotes: data.notes,
      },
      timerSettings: {
        showTimer: data.showTimer,
        flexibleTimer: data.flexibleTimer === "true" ? "Yes" : "No",
        startDate: data.startDataPeriod.slice(0, 10),
        startTime: format(new Date(data.startTimePeriod), "HH:mm:ss"),
        drawDate: data.startDate.slice(0, 10),
        drawTime: format(new Date(data.startTime), "HH:mm:ss"),
      },
      prizeSettings: {
        type: data.type === "default" ? "Money Default" : "Money Flexible",
        settings: {
          primaryCurrency: data.primaryCurrency,
          jackpotAmount: data.jackpotAmount,
          countryAndRegionSettings: data.prizeCountrySettings,
        },
      },
      paidJackpotSettings: {
        paidJackpotOption: data.jackpotOption === "free" ? "Free" : "Paid",
        primatyCurrency: data.paidPrimaryCurrency,
        feeAmount: data.feeAmount,
        countryAndRegionSettings: data.paidCountrySettings,
      },
    },
  };
};

export const parseParticipateFormToPost = (
  data: IJackpotParticipateForm,
  tag: "parcitipate" | "most"
): ResponseJackpotParticipate | ResponseJackpotMost => {
  const images = [
    {
      imageRedirectLink: data.redirectLink1,
      image: {
        url: data.imageUrl1,
        name: data.imageUrl1,
      },
      index: 1,
    },
    {
      imageRedirectLink: data.redirectLink2,
      image: {
        url: data.imageUrl2,
        name: data.imageUrl2,
      },
      index: 2,
    },
    {
      imageRedirectLink: data.redirectLink3,
      image: {
        url: data.imageUrl3,
        name: data.imageUrl3,
      },
      index: 3,
    },
    {
      imageRedirectLink: data.redirectLink4,
      image: {
        url: data.imageUrl4,
        name: data.imageUrl4,
      },
      index: 4,
    },
  ];
  if (tag === "parcitipate") {
    return {
      jackpotBlockConfiguration: {
        buttonRedirectLink: data.buttonLink || "",
        includedParcitipate: data.includedParcitipate || false,
      },
      imagesConfigurator: {
        images: images,
      },
    };
  } else {
    return {
      blockConfiguration: {
        buttonRedirectLink: data.buttonLink || "",
        includedMostChance: data.includedParcitipate || false,
      },
      imagesConfigurator: {
        images: images,
      },
    };
  }
};
export const parseParticipatePostToForm = (
  data: ResponseJackpotParticipate | ResponseJackpotMost,
  tag: "parcitipate" | "most"
): IJackpotParticipateForm => {
  if (tag === "parcitipate") {
    const { jackpotBlockConfiguration, imagesConfigurator } =
      data as ResponseJackpotParticipate;
    const { images } = imagesConfigurator;
    return {
      buttonLink: jackpotBlockConfiguration.buttonRedirectLink,
      includedParcitipate: jackpotBlockConfiguration.includedParcitipate,
      imageUrl1: images[0] ? images[0].image.url : "",
      redirectLink1: images[0] ? images[0].imageRedirectLink : "",
      imageUrl2: images[1] ? images[1].image.url : "",
      redirectLink2: images[1] ? images[1].imageRedirectLink : "",
      imageUrl3: images[2] ? images[2].image.url : "",
      redirectLink3: images[2] ? images[2].imageRedirectLink : "",
      imageUrl4: images[3] ? images[3].image.url : "",
      redirectLink4: images[3] ? images[3].imageRedirectLink : "",
    };
  } else {
    const { blockConfiguration, imagesConfigurator } =
      data as ResponseJackpotMost;
    const { images } = imagesConfigurator;

    return {
      buttonLink: blockConfiguration.buttonRedirectLink,
      includedParcitipate: blockConfiguration.includedMostChance,
      imageUrl1: images[0] ? images[0].image.url : "",
      redirectLink1: images[0] ? images[0].imageRedirectLink : "",
      imageUrl2: images[1] ? images[1].image.url : "",
      redirectLink2: images[1] ? images[1].imageRedirectLink : "",
      imageUrl3: images[2] ? images[2].image.url : "",
      redirectLink3: images[2] ? images[2].imageRedirectLink : "",
      imageUrl4: images[3] ? images[3].image.url : "",
      redirectLink4: images[3] ? images[3].imageRedirectLink : "",
    };
  }
};
