import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createGlobalProfile, getPrizeById, getPrizeWheel, getProfile, getProfilesWheel, patchPopupTranslation, patchPrizeWheel, postPopupTranslation, postPrizeWheel, postPrizeWheelTranslation, setConfigWheel, updateConfigWheelById, updateGlobalProfile } from "./thunks";

import { GameEntityWheel, IFormPrizeCreate, IGlobalProfile, IProfilesWheel, ITranslationProfile, IWheelConfige } from "../../entities/wheelGame";
import { RootState } from "..";

interface WheelState {
  loading: boolean,
  saveLoading: boolean,
  loadedProfile: boolean,
  published: boolean,
  prizes: GameEntityWheel[],
  errorPrize?: string,
  loadingPrize: boolean;
  loadingProfile: boolean,
  loadingPrizeTranslation: boolean;
  noPrizes: boolean,
  profiles: IProfilesWheel[],
  noProfiles: boolean,
  editGlobalProfile?: IGlobalProfile,
  wheelConfige: IWheelConfige[],
  translations: ITranslationProfile[],
  gameEntity?: IFormPrizeCreate
}

const initialState: WheelState = {
  prizes: [],
  profiles: [],
  published: false,
  loading: false,
  saveLoading: false,
  loadingPrize: false,
  loadedProfile: false,
  loadingProfile: false,
  loadingPrizeTranslation: false,
  noPrizes: false,
  noProfiles: false,
  wheelConfige: [],
  translations: [],
}

const addLoadingCases = (
  builder: ActionReducerMapBuilder<WheelState>,
  action: any
) => {
  builder.addCase(action.pending, (state: WheelState) => {
    state.saveLoading = true;
  });

  builder.addCase(action.fulfilled, (state: WheelState) => {
    state.saveLoading = false;
  });
};

export const wheelSlice = createSlice({
  name: "bonus",
  initialState,
  reducers: {
    setGlobalProfile: (state, action: PayloadAction<IGlobalProfile>) => {
      state.editGlobalProfile = action.payload
    },
    unsetGlobalProfile: (state) => {
      state.editGlobalProfile = undefined;
      state.loadedProfile = false;
    },
    clearPrizeState: (state) => {
      state.gameEntity = undefined;
      state.errorPrize = undefined;
      state.published = false;
    },
    addedErrorPrizeState: (state, action: PayloadAction<string>) => {
      state.errorPrize = action.payload;
    },
    clearErrorPrizeState: (state) => {
      state.errorPrize = undefined;

    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPrizeWheel.pending, (state) => {
      state.loading = true;
      state.noPrizes = false;
    })
    builder.addCase(getPrizeWheel.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.length === 0) {
        state.noPrizes = true;
        state.prizes = [];
      } else {
        state.prizes = action.payload.sort((a, b) => a.gameEntity.id - b.gameEntity.id);
        state.noPrizes = false;
      }
    })

    builder.addCase(getProfilesWheel.pending, (state) => {
      state.loading = true;
      state.noProfiles = false
    })
    builder.addCase(getProfilesWheel.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.noProfiles = true;
        state.profiles = [];
      } else {
        state.noProfiles = false;
        state.profiles = action.payload.sort((a, b) => a.id - b.id);
      }
      state.loading = false;
    })

    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
    })

    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      const { config, global, translations } = action.payload;
      if (global) {
        const result = {
          ...global,
          status: global.status === 'draft' ? 'paused' : global.status,
          triggerId: global.trigger.id
        }
        state.editGlobalProfile = result as IGlobalProfile;
      }
      state.wheelConfige = config;
      state.translations = translations;

      state.loading = false;
      state.loadedProfile = true;
    })

    builder.addCase(getPrizeById.pending, (state) => {
      state.loading = true;
      state.errorPrize = undefined;
      state.gameEntity = undefined;
    })
    builder.addCase(getPrizeById.fulfilled, (state, action) => {
      if (action.payload) {
        const { translations, name, bonusId, config, langs, id, published } = action.payload;
        state.gameEntity = {
          id: id,
          name: name,
          bonusId: bonusId.toString(),
          jackpot: `${config.jackpot}`,
          language: langs,
          translations: translations,
        }
        state.published = published;
      }
      state.loading = false;
    })

    builder.addCase(postPrizeWheel.pending, (state) => {
      state.loadingPrize = true;
      state.errorPrize = undefined;
    })
    builder.addCase(postPrizeWheel.fulfilled, (state) => {
      state.errorPrize = undefined;
      state.loadingPrize = false;
    })
    builder.addCase(postPrizeWheel.rejected, (state, action) => {
      state.errorPrize = action.error.message;
      state.loadingPrize = false;
    })

    builder.addCase(patchPrizeWheel.pending, (state) => {
      state.loadingPrize = true;
      state.errorPrize = undefined;
    })
    builder.addCase(patchPrizeWheel.fulfilled, (state) => {
      state.errorPrize = undefined;
      state.loadingPrize = false;
    })
    builder.addCase(patchPrizeWheel.rejected, (state, action) => {
      state.errorPrize = action.error.message;
      state.loadingPrize = false;
    })
    // postPrizeWheelTranslation
    builder.addCase(postPrizeWheelTranslation.pending, (state) => {
      state.loadingPrizeTranslation = true;
    })
    builder.addCase(postPrizeWheelTranslation.fulfilled, (state) => {
      state.loadingPrizeTranslation = false;
    })
    builder.addCase(postPrizeWheelTranslation.rejected, (state) => {
      state.loadingPrizeTranslation = false;
    })


    // setConfigWheel
    builder.addCase(setConfigWheel.pending, (state) => {
      state.loadingProfile = true;
      state.saveLoading = true;
    })
    builder.addCase(setConfigWheel.fulfilled, (state) => {
      state.loadingProfile = false;
      state.saveLoading = false;
    })
    builder.addCase(setConfigWheel.rejected, (state) => {
      state.loadingProfile = false;
      state.saveLoading = false;
    })
    // updateConfigWheelById
    builder.addCase(updateConfigWheelById.pending, (state) => {
      state.loadingProfile = true;
      state.saveLoading = true;
    })
    builder.addCase(updateConfigWheelById.fulfilled, (state) => {
      state.loadingProfile = false;
      state.saveLoading = false;
    })
    builder.addCase(updateConfigWheelById.rejected, (state) => {
      state.loadingProfile = false;
      state.saveLoading = false;
    })
    // postPopupTranslation
    builder.addCase(postPopupTranslation.pending, (state) => {
      state.loadingProfile = true;
      state.saveLoading = true;
    })
    builder.addCase(postPopupTranslation.fulfilled, (state) => {
      state.loadingProfile = false;
      state.saveLoading = false;
    })
    builder.addCase(postPopupTranslation.rejected, (state) => {
      state.loadingProfile = false;
      state.saveLoading = false;
    })
    // patchPopupTranslation
    builder.addCase(patchPopupTranslation.pending, (state) => {
      state.loadingProfile = true;
      state.saveLoading = true;
    })
    builder.addCase(patchPopupTranslation.fulfilled, (state) => {
      state.loadingProfile = false;
      state.saveLoading = false;
    })
    builder.addCase(patchPopupTranslation.rejected, (state) => {
      state.loadingProfile = false;
      state.saveLoading = false;
    })
    // createGlobalProfile
    builder.addCase(createGlobalProfile.rejected, (state) => {
      state.saveLoading = false;
    })
    // updateGlobalProfile
    builder.addCase(updateGlobalProfile.rejected, (state) => {
      state.saveLoading = false;
    })
    const saveActions = [
      createGlobalProfile,
      updateGlobalProfile
    ];
    saveActions.forEach((action) => addLoadingCases(builder, action));
  },
});

export const { unsetGlobalProfile, setGlobalProfile, clearPrizeState, clearErrorPrizeState, addedErrorPrizeState } = wheelSlice.actions;


export const selectWheel = (state: RootState) => state.wheel;
export const selectPrizesState = (state: RootState) => state.wheel.prizes;
export const selectProfilessState = (state: RootState) => state.wheel.profiles;

// export const selectModifierBonus = (state: RootState) => state.bonus.modifier;

export default wheelSlice.reducer;
