import { createSlice, Draft } from "@reduxjs/toolkit";
import { getPiggyEntityList, getPiggyProfilesList } from "./thunks";
import { PiggyEntity, PiggyProfile } from "../../entities/piggyGame";

interface PiggyBankState {
  loading: boolean;
  needUpdateEntites: boolean;
  needUpdateProfiles: boolean;
  entities: PiggyEntity[];
  profiles: PiggyProfile[];
}

const initialState: PiggyBankState = {
  loading: false,
  needUpdateEntites: true,
  needUpdateProfiles: true,
  entities: [],
  profiles: [],
};

export const piggyBankTableSlice = createSlice({
  name: "piggyBankTable",
  initialState,
  reducers: {
    handlerNeedUpdate: (state: Draft<PiggyBankState>) => {
      state.needUpdateEntites = true;
    },
    handlerNeedUpdateProfiles: (state: Draft<PiggyBankState>) => {
      state.needUpdateProfiles = true;
    },
  },
  extraReducers: (builder) => {
    // getPiggyEntityList - получение списка игр
    builder.addCase(getPiggyEntityList.pending, (state) => {
      state.loading = true;
      state.needUpdateEntites = false;
    });
    builder.addCase(getPiggyEntityList.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });
    // getPiggyProfilesList
    builder.addCase(getPiggyProfilesList.pending, (state) => {
      state.loading = true;
      state.needUpdateProfiles = false;
    });
    builder.addCase(getPiggyProfilesList.fulfilled, (state, action) => {
      state.profiles = action.payload;
      state.loading = false;
    });
  },
});
// export actions
export const { handlerNeedUpdate, handlerNeedUpdateProfiles } = piggyBankTableSlice.actions;

export default piggyBankTableSlice.reducer;
