import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../../config/config";
import { PiggyProfile, PiggyProfileGlobalSettings } from "../../../entities/piggyGame";
import { agent } from "../../../api";
import { handlerNeedUpdateProfiles } from "../piggyBankTableStore";
import { addNotification } from "../../Notification/notificateStore";

const piggyGameUrl = apiUrlV1 + "/organization/game/piggy-bank/profile";
/**
 * Создание профиля игры piggy bank
 * 
 * @param {PiggyProfileGlobalSettings} data - данные глобальной формы для создания {@link PiggyProfileGlobalSettings}
 */
export const postPiggyProfile = createAsyncThunk(
  "piggy/post-profile",
  async (params: { data: PiggyProfileGlobalSettings }, thunksApi): Promise<PiggyProfile> => {
    try {
      const response = await agent.post(
        `${piggyGameUrl}/create/from-global-settings`,
        {
          body: JSON.stringify(params.data),
        }
      );
      if (response.ok) {
        const data = await response.json() as PiggyProfile;
        thunksApi.dispatch(handlerNeedUpdateProfiles());
        thunksApi.dispatch(
          addNotification({ status: "success", text: "Successfully saved" })
        );
        return data;
      }

      throw Error("No response");
    } catch (error) {
      throw Error("Request is not found");
    }
  }
);

/**
 * Получение профиля игры по id piggy bank
 * 
 * @param {number} id - ID профиля 
 * 
 * @returns {PiggyProfile} возвращает профиль игры типа {@link PiggyProfile}
 */
export const getPiggyProfile = createAsyncThunk(
  "piggy/get-profile",
  async (params: { id: number }, thunksApi): Promise<PiggyProfile> => {
    try {
      const response = await agent.get(`${piggyGameUrl}/read/${params.id}`);
      if (response.ok) {
        const data = await response.json() as PiggyProfile;
        return data;
      }

      throw Error("No response");
    } catch (error) {
      throw Error("Request is not found");
    }
  }
);

/**
 * Изменение профиля игры piggy bank
 * 
 * @param {any} data - данные глобальной формы для создания
 * @param {number} id - id профиля
 * b
 * @returns {PiggyProfile} возвращает профиль игры типа {@link PiggyProfile}
 */
export const patchPiggyProfile = createAsyncThunk(
  "piggy/patch-profile",
  async (params: {
    data: {
      globalSettings?: PiggyProfileGlobalSettings,
      configuration?: {
        piggyBankGameId: number
      }
    }, id: number
  }, thunksApi): Promise<PiggyProfile> => {
    try {
      const response = await agent.patch(
        `${piggyGameUrl}/update/from-config-part/${params.id}`,
        {
          body: JSON.stringify(params.data),
        }
      );
      if (response.ok) {
        const data = await response.json() as PiggyProfile;
        thunksApi.dispatch(handlerNeedUpdateProfiles());
        thunksApi.dispatch(
          addNotification({ status: "success", text: "Successfully saved" })
        );
        return data;
      }

      throw Error("No response");
    } catch (error) {
      throw Error("Request is not found");
    }
  }
);
