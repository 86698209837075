import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent } from "../../../api";
import { apiUrlV1 } from "../../../config/config";
import { IPiggyGlobalSettings, PiggyEntity, ResponseConfig } from "../../../entities/piggyGame";
import { handlerNeedUpdate } from "../piggyBankTableStore";
import { addNotification } from "../../Notification/notificateStore";

const piggyGameUrl = apiUrlV1 + "/organization/game/piggy-bank";

/**
 * создание игры Piggy Bank.
 *
 * @param {IPiggyGlobalSettings} data Данные формы, типа {@link IPiggyGlobalSettings}
 *
 * @returns полный объект с пустым конфигом ({@link PiggyEntity})
 */
export const postPiggyEntity = createAsyncThunk(
  "piggy/post-entity",
  async (params: { data: IPiggyGlobalSettings }, thunksApi): Promise<PiggyEntity> => {
    try {
      const response = await agent.post(
        `${piggyGameUrl}/create/from-global-settings`,
        {
          body: JSON.stringify(params.data),
        }
      );
      if (response.ok) {
        const data = await response.json() as PiggyEntity;
        thunksApi.dispatch(handlerNeedUpdate());
        thunksApi.dispatch(
          addNotification({ status: "success", text: "Successfully saved" })
        );
        return data;
      }

      throw Error("No response");
    } catch (error) {
      throw Error("Request is not found");
    }
  }
);
/**
 * Получени данных об игре по id
 * 
 * @param {number} id ID игры.
 * 
 * @returns полный объект ({@link PiggyEntity})
 */
export const getEntityById = createAsyncThunk(
  'piggy/get-entity',
  async (param: { id: number }): Promise<PiggyEntity> => {
    try {
      const response = await agent.get(`${piggyGameUrl}/read/${param.id}`)
      if (response.ok) {
        const data = await response.json() as PiggyEntity;
        return data;
      }
      throw Error("No response");
    } catch (error) {
      throw Error("Request is not found");
    }
  }
)

/**
 * изменения в игре Piggy Bank.
 *
 * @param {ResponseConfig} data Данные формы, типа {@link ResponseConfig}
 * @param {number} id ID игры.
 * 
 * @returns полный объект ({@link PiggyEntity})
 */
export const patchPiggyEntity = createAsyncThunk(
  "piggy/patch-entity",
  async (params: { data: ResponseConfig, id: number }, thunksApi): Promise<PiggyEntity> => {
    try {
      const response = await agent.patch(
        `${piggyGameUrl}/update/from-config-part/${params.id}`,
        {
          body: JSON.stringify(params.data),
        }
      );
      if (response.ok) {
        const data = await response.json() as PiggyEntity;
        thunksApi.dispatch(handlerNeedUpdate());
        thunksApi.dispatch(
          addNotification({ status: "success", text: "Successfully saved" })
        );
        return data;
      }

      throw Error("No response");
    } catch (error) {
      throw Error("Request is not found");
    }
  }
);