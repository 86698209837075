import { RootState } from "..";
import { combineReducers } from "@reduxjs/toolkit";
import piggyBankTableSlice from "./piggyBankTableStore";
import piggyBankSlice from "./Database/piggyBankStore";
import piggyBankProfileSlice from './Profile/piggyProfileStore'

// export selectors
export const selectPiggyDataTable = (state: RootState) => state.piggyBank.tables;

export const selectPiggyState = (state: RootState) => state.piggyBank.entity;

export const selectPiggyProfile = (state: RootState) => state.piggyBank.profile;

export const piggyBankReducer = combineReducers({
  tables: piggyBankTableSlice,
  entity: piggyBankSlice,
  profile: piggyBankProfileSlice,
});
