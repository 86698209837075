import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { EventResponseMetaData, EventsFilter, UserEvents } from "../../entities/event";
import { getActiveUsersInMonthly, getUserEvents } from "./thunks";

interface EventState {
  loading: boolean;
  loadingActiveUsers: boolean;
  activeUsersLoaded: boolean;
  userEvents: UserEvents[];
  filter: EventsFilter;
  meta: EventResponseMetaData;
  monthlyActiveUsersCount: number,
}

const initialState: EventState = {
  loading: false,
  loadingActiveUsers: false,
  activeUsersLoaded: false,
  userEvents: [],
  filter: {},
  meta: {
    limit: 250,
    page: 1,
    pages: 1,
    total: 0,
    totalUniqueUsers:0,
  },
  monthlyActiveUsersCount: 0,
};

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    handlerFilter: (state: Draft<EventState>, action: PayloadAction<EventsFilter>) => {
      state.filter = action.payload;
    }
  },
  extraReducers: (builder) => {
    // getUserEvents
    builder.addCase(getUserEvents.pending, (state: Draft<EventState>) => {
      state.loading = true;
    })
    builder.addCase(getUserEvents.fulfilled, (state: Draft<EventState>, action) => {
      state.loading = false;
      if (action.payload) {
        const { data, ...meta } = action.payload
        state.userEvents = data;
        state.meta = meta;
      }
    })
    builder.addCase(getUserEvents.rejected, (state: Draft<EventState>) => {
      state.loading = false;
    })
    //getActiveUsersInMonthly 
    builder.addCase(getActiveUsersInMonthly.pending, (state: Draft<EventState>) => {
      state.loadingActiveUsers = true;
    })
    builder.addCase(getActiveUsersInMonthly.fulfilled, (state: Draft<EventState>, action) => {
      state.loadingActiveUsers = false;
      state.activeUsersLoaded = true;
      state.monthlyActiveUsersCount = action.payload;
    })
    builder.addCase(getActiveUsersInMonthly.rejected, (state: Draft<EventState>) => {
      state.loadingActiveUsers = false;
    })
  },
});

export const { handlerFilter } = eventSlice.actions;

export const selectEventState = (state: RootState) => state.event;

export default eventSlice.reducer;
