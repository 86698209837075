import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { PiggyProfile, PiggyProfileConfig } from "../../../entities/piggyGame";
import { getPiggyProfile } from "./thunks";

interface PiggyBankState {
  loading: boolean;
  saveLoading: boolean;
  profile?: PiggyProfile;
  config?: PiggyProfileConfig;
}

const initialState: PiggyBankState = {
  loading: false,
  saveLoading: false,
};

export const piggyBankProfileSlice = createSlice({
  name: "piggyBankEntity",
  initialState,
  reducers: {
    clearEntity: (state) => {
      state.profile = undefined;
      state.config = undefined;
    }
  },
  extraReducers: (builder) => {
    // getPiggyProfile
    builder.addCase(getPiggyProfile.pending, (state) => {
      state.loading = true;
      state.saveLoading = true;
    });
    builder.addCase(getPiggyProfile.fulfilled, (state: Draft<PiggyBankState>, actions: PayloadAction<PiggyProfile>) => {
      state.loading = false;
      state.saveLoading = false;
      state.profile = actions.payload;
      state.config = actions.payload.config;
    });
  },
});
// export actions
export const { clearEntity } = piggyBankProfileSlice.actions;

export default piggyBankProfileSlice.reducer;
