import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent } from "../../api";
import { apiUrlV1 } from "../../config/config";
import { IReqWebhook, Webhook, WebhookHistory } from "../../entities/webhook";

export const getWebhooks = createAsyncThunk(
  "webhook/get-webhook-list",
  async (): Promise<Webhook[]> => {
    const res = await agent.get(`${apiUrlV1}/webhook/`);
    if (res.ok) {
      const data = await res.json() as IReqWebhook;
      return data.data;
    }
    return [];
  }
);

export const getWebhook = createAsyncThunk(
  "webhook/get-webhook",
  async (id: string | number): Promise<Webhook | undefined> => {
    const res = await agent.get(`${apiUrlV1}/webhook/${id}`);
    if (res.ok) {
      const data = await res.json();
      return data;
    }
    return undefined;
  }
);

export const getWebhookHistory = createAsyncThunk(
  `webhook/get-webhook-history`,
  async (id: number | string): Promise<WebhookHistory[]> => {
    const res = await agent.get(`${apiUrlV1}/webhook/history/${id}`);
    if (res.ok) {
      const req = (await res.json()) as WebhookHistory[];
      return req;
    }
    return [];
  }
);
