import { createAsyncThunk } from "@reduxjs/toolkit";
import { EventsFilter, ResponseEventList } from "../../entities/event";
import { agent, encodeGetParams } from "../../api";
import { apiUrlV1 } from "../../config/config";

export const getUserEvents = createAsyncThunk(
  'event/get-user-events',
  async (params?: { filter: EventsFilter }): Promise<ResponseEventList | undefined> => {
    try {
      const query = encodeGetParams({ ...params?.filter })
      const response = await agent.get(`${apiUrlV1}/organization/user-events/read/list${query}`)
      if (response.ok) {
        const data = await response.json() as ResponseEventList;
        return data;
      }
      if (!response.ok) {
        throw Error("No response");
      }
      return undefined;
    } catch (error) {
      throw Error("Request is not found");
    }
  }
)

export const getActiveUsersInMonthly = createAsyncThunk(
  'event/get-active-users-in monthly',
  async (): Promise<number> => {
    try {
      const response = await agent.get(`${apiUrlV1}/organization/user-events/read/monthly-active-users-count`)
      if (response.ok) {
        const data = await response.json();
        return data;
      }
      if (!response.ok) {
        throw Error("No response");
      }
      return 0;
    } catch (error) {
      throw Error("Request is not found");
    }
  }
)