import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { DiceIcon } from "../Svg/DiceIcon";
import { BoltIcon } from "../Svg/Bolt";
import { StarIcon } from "../Svg/StarIcon";
import { DoorIcon } from "../Svg/DoorIcon";
import { UserIcon } from "../Svg/UserIcon";
import { SettingIcon } from "../Svg/SettingIcon";
import { IntegrationIcon } from "../Svg/IntegrationIcon";
import { useAppSelector } from "../../store";
import { selectAuthState } from "../../store/Auth/authStore";
import React from "react";
import { colors } from "../../Theme/ColorsForTheam";
import { ArrowBottom } from "../Svg/ArrowBack";
import WebhookIcon from "@mui/icons-material/Webhook";
import EventIcon from "@mui/icons-material/Event";
import { getMonthDates } from "../../utils/getCurrenMonth";
import { format } from "date-fns";

interface INavMenu {
  handlerModalOpen: () => void;
}
interface INavMenuItem {
  onClick: () => void;
  titleKey: string;
  icon: React.ReactNode;
  disabled?: boolean;
}

const ProfileButton = styled(Button, {
  name: "ProfileButton",
  slot: "root",
})(() => ({
  color: colors.gray[950],
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textTransform: "inherit",
  height: "24px",
}));

const NavMenuItem = (props: INavMenuItem) => {
  const { icon, titleKey, onClick } = props;
  const { t } = useTranslation();
  return (
    <MenuItem
      sx={{
        minWidth: 160,
        width: 160,
      }}
      onClick={onClick}
      disabled={props.disabled}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{t(`${titleKey}`)}</ListItemText>
    </MenuItem>
  );
};

export const NavMenu = ({ handlerModalOpen }: INavMenu) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { profile } = useAppSelector(selectAuthState);

  const [open, setOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const pushToPage = (toUrl: string) => {
    setTimeout(() => {
      navigate(toUrl);
    }, 100);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };
  const [startDate, currentDate] = getMonthDates();
  const navList: INavMenuItem[] = [
    {
      titleKey: "Header.games",
      onClick: () => {
        pushToPage("/");
      },
      icon: <DiceIcon />,
    },
    {
      titleKey: "Users",
      onClick: () => {
        pushToPage("/users");
      },
      disabled: true,
      icon: <UserIcon />,
    },
    {
      titleKey: "User Roles",
      onClick: () => {
        pushToPage("/roles");
      },
      disabled: true,
      icon: <SettingIcon />,
    },
    {
      titleKey: "Integrations",
      onClick: () => {
        pushToPage("/integrations");
      },
      icon: <IntegrationIcon />,
    },
    {
      titleKey: "Webhooks",
      onClick: () => {
        pushToPage("/webhook");
      },
      icon: <WebhookIcon />,
    },
    {
      titleKey: "Events",
      onClick: () => {
        pushToPage(
          `/events?event=bonus_awarded&fromDate=&fromDate=${format(
            startDate,
            "yyyy-MM-dd'T'HH:mm:ss"
          )}&toDate=${format(currentDate, "yyyy-MM-dd'T'HH:mm:ss")}`
        );
      },
      icon: <EventIcon />,
    },
    {
      titleKey: "Header.trigger",
      onClick: () => {
        pushToPage("/trigger");
      },
      icon: <BoltIcon />,
    },
    {
      titleKey: "Header.bonus",
      onClick: () => {
        pushToPage("/bonus");
      },
      icon: <StarIcon />,
    },
    {
      titleKey: "Header.logout",
      onClick: () => {
        handlerModalOpen();
      },
      icon: <DoorIcon />,
    },
  ];

  React.useEffect(() => {
    setOpen(false);
  }, [location.pathname]);
  return (
    <>
      <ProfileButton
        id="basic-button"
        type="button"
        variant="text"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<ArrowBottom open={open} />}
        onClick={handleClick}
      >
        {profile?.email}
      </ProfileButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="basic-button"
      >
        {navList.map((item) => (
          <NavMenuItem key={item.titleKey} {...item} />
        ))}
      </Menu>
    </>
  );
};
