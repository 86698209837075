import { createSlice } from "@reduxjs/toolkit";
import { getEntityById, patchPiggyEntity, postPiggyEntity } from "./thunks";
import { PiggyEntity, PiggyEntityConfig, PiggyEntityValidation } from "../../../entities/piggyGame";

interface PiggyBankState {
  loading: boolean;
  saveLoading: boolean;
  entity?: PiggyEntity;
  config?: PiggyEntityConfig;
  validation?: PiggyEntityValidation
}

const initialState: PiggyBankState = {
  loading: false,
  saveLoading: false,
};

export const piggyBankSlice = createSlice({
  name: "piggyBankEntity",
  initialState,
  reducers: {
    clearEntity: (state) => {
      state.entity = undefined;
      state.config = undefined;
      state.validation = undefined;
    }
  },
  extraReducers: (builder) => {
    // postPiggyEntity - создание
    builder.addCase(postPiggyEntity.pending, (state) => {
      state.loading = true;
      state.saveLoading = true;
    });
    builder.addCase(postPiggyEntity.fulfilled, (state, action) => {
      state.entity = action.payload;
      state.config = action.payload.config;
      state.validation = action.payload.validation;
      state.loading = false;
      state.saveLoading = false;
    });
    builder.addCase(postPiggyEntity.rejected, (state) => {
      state.loading = false;
      state.saveLoading = false;
    });
    // getEntityById - получение
    builder.addCase(getEntityById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEntityById.fulfilled, (state, action) => {
      state.entity = action.payload;
      state.config = action.payload.config;
      state.validation = action.payload.validation;
      state.loading = false;
    });
    builder.addCase(getEntityById.rejected, (state) => {
      state.loading = false;
    });
    // patchPiggyEntity - изменение и обновление state
    builder.addCase(patchPiggyEntity.pending, (state) => {
      state.saveLoading = true;
    });
    builder.addCase(patchPiggyEntity.fulfilled, (state, action) => {
      state.entity = action.payload;
      state.config = action.payload.config;
      state.validation = action.payload.validation;
      state.saveLoading = false;
    });
    builder.addCase(patchPiggyEntity.rejected, (state) => {
      state.saveLoading = false;
    });
  },
});
// export actions
export const { clearEntity } = piggyBankSlice.actions;

export default piggyBankSlice.reducer;
